body {
  margin: 0;
  font-family: 'Quicksand', sans-serif;
    /* Use Quicksand for body text */
    line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Cinzel', serif;
  /* Use Cinzel for headings */
}

p,
li,
a {
  font-family: 'Quicksand', sans-serif;
  /* Ensure other text elements use Quicksand */
}